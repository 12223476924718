<template>
  <div>
    <div class="dice">
      <Header>
        <div class="links">
          <button @click="showMiddle = !showMiddle">
            <div class="toggle" :class="{ active: showMiddle }">
              <span></span>
            </div>
            Середина
          </button>
          <button @click="changeCommission">
            <div class="toggle" :class="{ active: showCommision }">
              <span></span>
            </div>
            Комиссия
          </button>
        </div>
      </Header>
      <div class="flex">
        <div class="left">
          <div class="forms">
            <Form
              class="form"
              :value="amount"
              v-model="amount"
              :min="1"
              :max="userBalance.balance"
              name="Сумма"
            />
            <Form
              class="form"
              :value="chance"
              v-model="chance"
              :min="1"
              :max="90"
              name="Шанс"
            />
          </div>

          <Hash
            v-if="auth"
            :show="fair.show"
            :hash="hash"
            @check="fair.modal = true"
            @download="download(hash)"
          />

          <transition name="fade" tag="div">
            <Modal
              v-if="fair.modal"
              :fair="fair"
              @download="download(fair.hash)"
              @close="fair.modal = false"
            />
          </transition>
        </div>

        <div class="game">
          <div class="x">
            <animated-number
              :value="winSum"
              :formatValue="(value) => value.toFixed(1)"
              :duration="300"
            />
          </div>
          <div class="description">Возможный выигрыш</div>
          <div class="choices">
            <div class="choice">
              <span v-if="ranges[0]"
                >{{ ranges[0][0] }} - {{ ranges[0][1] }}</span
              >
              <button :disabled="!auth" @click="play('less')">Меньше</button>
            </div>
            <div v-if="showMiddle" class="choice">
              <span v-if="ranges[1]"
                >{{ ranges[1][0] }} - {{ ranges[1][1] }}</span
              >
              <button :disabled="!auth" @click="play('middle')">
                Середина
              </button>
            </div>
            <div class="choice">
              <span v-if="ranges[2]"
                >{{ ranges[2][0] }} - {{ ranges[2][1] }}</span
              >
              <button :disabled="!auth" @click="play('more')">Больше</button>
            </div>
          </div>
          <transition name="fade" tag="div">
            <Alert v-if="alert" :alert="alert" />
          </transition>
        </div>
      </div>
    </div>
    <History />
  </div>
</template>

<script>
import Modal from "@/components/Games/Dice/Modal";
import Header from "@/components/Games/Dice/Header";
import Form from "@/components/Games/Dice/Form";
import History from "@/components/Table/History";
import Hash from "@/components/Games/Dice/Hash";
import Alert from "@/components/Games/Dice/Alert";
import { mapGetters, mapState } from "vuex";
import AnimatedNumber from "animated-number-vue";

export default {
  components: {
    Header,
    Form,
    History,
    Hash,
    Alert,
    AnimatedNumber,
    Modal,
  },
  data() {
    return {
      fair: {
        show: false,
        modal: false,
      },
      broadcast: null,
      isRequest: false,
      showMiddle: false,
      showCommision: false,
      amount: 1,
      chance: 50,
      hash: null,
      alert: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", {
      auth: "isLoggedIn",
      userBalance: "getBalance",
      convertAmount: "convertAmount",
    }),
    winSum() {
      let { amount, chance } = this.$data;
      if (!amount || !chance) return 0;
      const winSum = Number((amount * (100 / chance)).toFixed(2));
      return isNaN(winSum)
        ? 0
        : (winSum * (this.showCommision ? 0.95 : 1)).toFixed(2);
    },
    ranges() {
      const rangeLess = [0, Math.floor((this.chance / 100) * 999999)];
      const rangeMiddle = [
        Math.floor(500000 - (this.chance / 200) * 999999),
        Math.floor(500000 + (this.chance / 200) * 999999),
      ];
      const rangeMore = [
        Math.floor(1000000 - (this.chance / 100) * 999999),
        999999,
      ];
      return [rangeLess, rangeMiddle, rangeMore];
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    if (this.broadcast !== null) this.broadcast.unsubscribe();
  },
  methods: {
    async init() {
      if (!this.auth) return false;
      this.isRequest = true;
      try {
        let { data } = await this.$http.get("games/dices/classic/init");
        let { hash } = data.response;
        this.hash = hash;
        this.events();
      } catch (error) {
        this.$toast.error(error?.message);
      }
      this.isRequest = false;
    },
    async play(type) {
      if (this.isRequest) return false;
      this.alert = null;
      this.isRequest = true;

      this.fair = {
        show: false,
        modal: false,
        type,
      };

      try {
        let { data } = await this.$http.post("games/dices/classic/play", {
          type,
          chance: this.chance,
          currency: this.userBalance.active,
          houseEdge: Number(this.showCommision),
          amount: this.convertAmount(
            this.amount,
            this.userBalance.active,
            false
          ),
        });

        if (!data.success) {
          this.alert = { status: "error", message: data.error };
        } else {
          let { game, newgame, balances } = data.response;

          this.$store.commit("user/balance", balances);
          this.alert = {
            status: game.status === 1 ? "success" : "error",
            message: `Выпало: ${game.ticket}`,
          };

          this.hash = newgame.hash;

          this.fair = {
            ...this.fair,
            password: game.password,
            ticket: game.ticket,
            hash: game.hash,
            show: true,
            amount: this.amount,
            chance: this.chance,
            x: game.x,
            win: game.win,
          };
        }
      } catch (error) {
        this.alert = { status: "error", message: error.message };
      }

      this.isRequest = false;
    },
    async download(hash) {
      try {
        let { body } = await this.$http.get(
          "games/dices/classic/download/" + hash,
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([body], { type: "application/zip" });
        location.href = URL.createObjectURL(blob);
      } catch (err) {
        this.$toast.error(err?.message);
      }
    },
    events() {
      this.broadcast = this.$centrifuge.subscribe("games:dice_classic");
    },
    changeCommission() {
      if (!this.showCommision) {
        this.showCommision = true;
        return;
      }
      this.$confirm({
        title: "Внимание",
        message:
          "При выключении комиссии, ваш уровень и бонусы не прокачиваются, а Wager не отыгрывается!",
        button: {
          no: "Отменить",
          yes: "Продолжить",
        },
        callback: (confirm) => {
          if (confirm) this.showCommision = false;
        },
      });
    },
  },
  watch: {
    auth() {
      this.init();
    },
    "user.cent_token"() {
      this.events();
    },
  },
};
</script>

<style lang="scss" scoped>
.dice {
  border-radius: 6px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: white;
  .flex {
    justify-content: center;
    display: flex;
    padding: 35px 0;
    min-height: 355px;
    flex-wrap: wrap;
    .left {
      width: 520px;
      margin-right: 35px;
      .forms {
        display: flex;
        margin: 0 -10px;
        .form {
          width: calc(100% - 10px);
          margin: 0 10px;
        }
      }
    }
    .game {
      width: 667px;
      .x {
        text-align: center;
        font-size: 85px;
      }
      .description {
        color: #a3afc9;
        text-align: center;
        font-size: 15px;
      }
      .choices {
        display: flex;
        margin: 30px -5px 0 -5px;
        .choice {
          width: calc(100% - 10px);
          text-align: center;
          margin: 0 5px 10px 5px;
          span {
            color: #949eb5;
            display: block;
            margin-bottom: 10px;
          }
          button {
            display: block;
            width: 100%;
            border-radius: 6px;
            background-color: #7987a1;
            padding: 15px;
            color: #ffffff;
            font-size: 15px;
            transition: 200ms ease;
            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      .left {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
}

.links {
  display: flex;
  button {
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    background-color: #f5f6fa;
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-left: 15px;
    cursor: pointer;
    .toggle {
      background: #ffffff;
      border-radius: 100px;
      width: 40px;
      height: 25px;
      margin-right: 10px;
      align-items: center;
      display: flex;
      padding: 0 5px;
      &.active {
        justify-content: flex-end;
      }
      span {
        background: #0085ff;
        border-radius: 100px;
        width: 15px;
        height: 15px;
        display: block;
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    button {
      margin-left: 0;
      width: calc(100% / 2 - 10px);
    }
  }
}
</style>
