var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"container"},[_c('img',{staticClass:"close",attrs:{"alt":"close","src":require("@/assets/icons/close-modal.svg")},on:{"click":_vm.onClickOutside}}),_c('div',{staticClass:"title"},[_vm._v("Проверка игры")]),_c('div',{staticClass:"stats"},[_c('div',{staticClass:"stat"},[_c('div',{staticClass:"score"},[_vm._v(_vm._s(_vm.fair.amount))]),_c('div',{staticClass:"description"},[_vm._v("Размер игры")])]),_c('div',{staticClass:"stat"},[_c('div',{staticClass:"score"},[_vm._v("x"+_vm._s(_vm.fair.x))]),_c('div',{staticClass:"description"},[_vm._v("Коэффициент")])]),_c('div',{staticClass:"stat"},[_c('div',{staticClass:"score"},[_vm._v(" "+_vm._s(_vm.convertAmount(_vm.fair.win, _vm.fair.currency))+" ")]),_c('div',{staticClass:"description"},[_vm._v("Результат")])])]),_c('div',{staticClass:"progress-bar",style:({
          'justify-content':
            _vm.fair.type === 'middle'
              ? 'center'
              : _vm.fair.type === 'less'
              ? 'start'
              : 'end',
        })},[_c('div',{staticClass:"badge",style:({
            left: ("calc(" + ((_vm.fair.ticket / 999999) * 100) + "% - " + (74 / 2) + "px)"),
          })},[_vm._v(" "+_vm._s(_vm.fair.ticket)+" ")]),_c('div',{staticClass:"line",style:({ width: _vm.fair.chance + '%' })})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Хеш игры")]),_c('div',{staticClass:"group"},[_c('span',[_vm._v(_vm._s(_vm.fair.hash))]),_c('img',{attrs:{"alt":"copy","src":require("@/assets/icons/dice-copy.png")},on:{"click":function($event){return _vm.copy(_vm.fair.hash)}}})])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Число")]),_c('div',{staticClass:"group"},[_c('span',[_vm._v(_vm._s(_vm.fair.ticket))]),_c('img',{attrs:{"alt":"copy","src":require("@/assets/icons/dice-copy.png")},on:{"click":function($event){return _vm.copy(_vm.fair.ticket)}}})])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Пароль от архива")]),_c('div',{staticClass:"group"},[_c('span',[_vm._v(_vm._s(_vm.fair.password))]),_c('img',{attrs:{"alt":"copy","src":require("@/assets/icons/dice-copy.png")},on:{"click":function($event){return _vm.copy(_vm.fair.password)}}})])])]),_c('button',{on:{"click":function($event){return _vm.$emit('download')}}},[_vm._v("Скачать архив")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }