<template>
  <div
    class="alert"
    :class="{
      success: alert.status === 'success',
      danger: alert.status === 'error',
    }"
  >
    {{ alert.message }}
  </div>
</template>

<script>
export default {
  props: {
    alert: {
      status: String,
      message: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  background: linear-gradient(90deg, #0cbf88 0%, #12ca56 100%);
  box-shadow: 0px 4px 4px rgba(15, 196, 109, 0.15);
  border-radius: 6px;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 15px;
  //   font-weight: 500;
  &.danger {
    background: #ff3030;
    box-shadow: 0 8px 15px rgb(255 48 48 / 25%);
  }
}
</style>
