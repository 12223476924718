<template>
  <div>
    <div class="box">
      <div class="title">Hash игры</div>
      <div class="hash" @click="$emit('download')">
        {{ hash }}
      </div>
      <transition name="fade" tag="div">
        <button v-if="show" @click="$emit('check')">Проверить игру</button>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hash: String,
    show: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.box {
  text-align: center;
  margin-top: 30px;
  .title {
    text-transform: uppercase;
    color: #b6b0d7;
    font-weight: 400;
  }
  .hash {
    word-break: break-all;
    color: #b9b1d7;
    font-size: 15px;
    margin: 10px 0;
    transition: 200ms ease;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  button {
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: #b9b1d7;
    font-size: 17px;
  }
}
</style>
