<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div v-click-outside="onClickOutside" class="container">
        <img
          alt="close"
          class="close"
          src="@/assets/icons/close-modal.svg"
          @click="onClickOutside"
        />
        <div class="title">Проверка игры</div>
        <div class="stats">
          <div class="stat">
            <div class="score">{{ fair.amount }}</div>
            <div class="description">Размер игры</div>
          </div>
          <div class="stat">
            <div class="score">x{{ fair.x }}</div>
            <div class="description">Коэффициент</div>
          </div>
          <div class="stat">
            <div class="score">
              {{ convertAmount(fair.win, fair.currency) }}
            </div>
            <div class="description">Результат</div>
          </div>
        </div>

        <div
          class="progress-bar"
          :style="{
            'justify-content':
              fair.type === 'middle'
                ? 'center'
                : fair.type === 'less'
                ? 'start'
                : 'end',
          }"
        >
          <div
            class="badge"
            :style="{
              left: `calc(${(fair.ticket / 999999) * 100}% - ${74 / 2}px)`,
            }"
          >
            {{ fair.ticket }}
          </div>
          <div class="line" :style="{ width: fair.chance + '%' }"></div>
        </div>

        <div class="form-group">
          <label>Хеш игры</label>
          <div class="group">
            <span>{{ fair.hash }}</span>
            <img
              alt="copy"
              @click="copy(fair.hash)"
              src="@/assets/icons/dice-copy.png"
            />
          </div>
        </div>

        <div class="flex">
          <div class="form-group">
            <label>Число</label>
            <div class="group">
              <span>{{ fair.ticket }}</span>
              <img
                alt="copy"
                @click="copy(fair.ticket)"
                src="@/assets/icons/dice-copy.png"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Пароль от архива</label>
            <div class="group">
              <span>{{ fair.password }}</span>
              <img
                alt="copy"
                @click="copy(fair.password)"
                src="@/assets/icons/dice-copy.png"
              />
            </div>
          </div>
        </div>

        <button @click="$emit('download')">Скачать архив</button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";

export default {
  props: {
    fair: Object,
  },
  computed: mapGetters("user", ["convertAmount"]),
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    copy(text) {
      navigator.clipboard.writeText(text);
      this.$toast.success("Успешно скопировано");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 445px;
      margin: 0px auto;
      background-color: white;
      border-radius: 34px;
      //   display: flex;
      padding: 30px;
      position: relative;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      img.close {
        position: absolute;
        right: 30px;
        top: 30px;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #3a3e46;
        margin-bottom: 15px;
      }
      .stats {
        display: flex;
        margin-bottom: 25px;
        justify-content: space-between;
        .stat {
          width: 124px;
          background: #ffffff;
          border: 1px solid #e7eaf1;
          padding: 13px 0;
          border-radius: 6px;
          text-align: center;
          .score {
            font-size: 16px;
            color: #000000;
            margin-bottom: 3px;
            font-weight: 600;
          }
          .description {
            font-size: 13px;
            color: #b3b8bc;
          }
        }
      }
      .progress-bar {
        position: relative;
        height: 8px;
        margin-bottom: 35px;
        border-radius: 30px;
        background: #ff5959;
        display: flex;
        .line {
          background: #8bd252;
          height: 8px;
          border-radius: 30px;
          width: 40%;
        }
        .badge {
          left: 50%;
          top: 15px;
          background-color: white;
          border-radius: 6px;
          font-size: 15px;
          box-shadow: 0px 5px 15px rgba(210, 212, 221, 0.45);
          padding: 5px 10px;
          position: absolute;
        }
      }
      .form-group {
        margin-bottom: 10px;
        label {
          color: #a5b4bf;
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .group {
          border: 1px solid #e6e8ef;
          border-radius: 6px;
          position: relative;
          height: 50px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            width: calc(100% - 60px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img:hover {
            opacity: 0.6;
            cursor: pointer;
          }
        }
      }

      button {
        background: #0085ff;
        border-radius: 6px;
        color: white;
        padding: 15px 25px;
        margin: 15px auto auto auto;
        display: block;
        font-size: 15px;
        transition: 200ms ease;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-group {
          width: calc(50% - 10px);
        }
      }
    }
  }
}
</style>
