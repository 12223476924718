<template>
  <div class="form">
    <span class="title">{{ name }}</span>
    <input
      type="text"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <div class="types">
      <span @click="update('max')">Max</span>
      <span @click="update('min')">Min</span>
      <span @click="update('x2')">x2</span>
      <span @click="update('d2')">/2</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "value", "min", "max"],
  methods: {
    update(type) {
      let value = this.value;
      value = parseFloat(value);
      if (type === "min" || type === "max")
        value = type === "min" ? this.min : this.max;
      else {
        if (type === "x2") value *= 2;
        if (type === "d2") value /= 2;
        if (value < 1) value = 1;
      }
      this.$emit("input", value.toFixed(2));
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  span.title {
    display: block;
    color: #a3afc9;
  }
  input {
    display: block;
    margin-top: 10px;
    border: 1px solid #e3e9f2;
    border-radius: 6px 6px 0px 0px;
    border-bottom: none;
    color: #2c3034;
    text-align: center;
    font-weight: 500;
    width: 100%;
    font-size: 20px;
    padding: 15px;
  }
  .types {
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #e3e9f2;
    display: flex;
    span {
      width: calc(100% / 4);
      border-right: 1px solid #e3e9f2;
      text-align: center;
      font-weight: 500;
      color: #9fa6c5;
      padding: 10px 0;
      transition: 200ms ease;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
